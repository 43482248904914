/* APP */
export const UPDATE_CURRENT_BREAKPOINT = 'UPDATE_CURRENT_BREAKPOINT';
/* MODAL */
export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'
/* POLL */
export const POLL_CLOSE_PROFILE = 'POLL_CLOSE_PROFILE'
export const POLL_OPEN_PROFILE = 'POLL_OPEN_PROFILE'
export const POLL_SET_USER = 'POLL_SET_USER'
export const POLL_SET_ID = 'POLL_SET_ID'
export const POLL_SET_ADMIN = 'POLL_SET_ADMIN'
export const POLL_SET_POLL = 'POLL_SET_POLL'
export const POLL_SET_SUGGESTIONS = 'POLL_SET_SUGGESTIONS'
export const POLL_SET_PARTICIPANTS = 'POLL_SET_PARTICIPANTS'
export const POLL_SYNC_PARTICIPANTS = 'POLL_SYNC_PARTICIPANTS'
export const POLL_REPLACE_PARTICIPANT = 'POLL_REPLACE_PARTICIPANT'
export const POLL_REMOVE_PARTICIPANT = 'POLL_REMOVE_PARTICIPANT'
export const POLL_SET_PARTICIPANTS_COUNT = 'POLL_SET_PARTICIPANTS_COUNT'
export const POLL_SET_EMBED = 'POLL_SET_EMBED'
export const POLL_SET_EMBED_CLICKED = 'POLL_SET_EMBED_CLICKED'

/* SUGGESTION */
export const SUGGESTION_SET_TEMP = 'SUGGESTION_SET_TEMP'