import * as types from '../mutation-types'

// initial state
const state = {
    open: false,
}

// getters
const getters = {
    open: state => state.open
}

// actions
const actions = {
    open({commit}) {
        commit(types.MODAL_OPEN)
    },
    close({commit}) {
        commit(types.MODAL_CLOSE)
    }
}

// mutations
const mutations = {
    [types.MODAL_OPEN](state) {
        state.open = true
    },
    [types.MODAL_CLOSE](state) {
        state.open = false
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
