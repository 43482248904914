import * as types from '../mutation-types'

// initial state
const state = {
    currentBreakpoint: null,
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
}

const getters = {
    currentBreakpoint: state => state.currentBreakpoint,
    isMobile(state) {
        if(state.currentBreakpoint === null) {
            return false
        }
        return state.breakpoints.indexOf(state.currentBreakpoint) < state.breakpoints.indexOf('sm');
    }
}

const actions = {
    updateBreakpoint ({commit}, breakpoint) {
        commit(types.UPDATE_CURRENT_BREAKPOINT, breakpoint)
    },
}

const mutations = {
    [types.UPDATE_CURRENT_BREAKPOINT] (state, breakpoint) {
        state.currentBreakpoint = breakpoint
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}