import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyDPhJr09cV8IgVlVp9aSZ5dFiM-fZT-oE0",
    authDomain: "poll-ly.firebaseapp.com",
    databaseURL: "https://poll-ly.firebaseio.com",
    projectId: "poll-ly",
    storageBucket: "poll-ly.appspot.com",
    messagingSenderId: "1012904065965",
    appId: "1:1012904065965:web:b5457ca4da75caaf3d3f24",
    measurementId: "G-H49K46DBZ1"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
    db
}