import * as types from '../mutation-types'
import poll from '../../api/poll';
import {censorWords} from "@/helper/censor-words";
import Vue from 'vue'
import {
    onSnapshot
} from "firebase/firestore";

// initial state
const state = {
    user: null,
    id: null,
    admin: null,
    embed: false,
    embedClicked: false,
    poll: null,
    suggestions: null,
    participants: null,
    participantsCount: null,
    maxVotes: 0,
    openProfile: false,
    tempSuggestion: null,
    suggestionFilterOptions: [
        { text: 'Most voted', value: 'votes_desc' },
        { text: 'Least voted', value: 'votes_asc' },
        { text: 'Newest', value: 'date_desc' },
        { text: 'Oldest', value: 'date_asc' }
    ]
}
const watchers = {
    participants: []
}

let pariticpantsSyncing = false;

// getters
const getters = {
    user: state => state.user,
    id: state => state.id,
    admin: state => state.admin,
    embed: state => state.embed,
    isEmbedClicked: state => state.embedClicked,
    poll: state => state.poll,
    question: state => state.poll.question,
    loggedIn: state => !!(state.participants && state.participants.find((p) => p.id === state.user.uid)),
    suggestionFilterOptions: state => state.suggestionFilterOptions,
    suggestions: state => {
        if (state.poll.bannedWordListEnabled && state.poll.bannedWordList.length > 0 && state.suggestions) {
            const suggestions = state.suggestions.map(a => ({...a}))
            for (const key in suggestions) {
                suggestions[key].title = censorWords(suggestions[key].title, state.poll.bannedWordList)
            }
            return suggestions
        } else {
            return state.suggestions
        }
    },
    participants: state => {
        let participants = []
        let currentUser = null
        if (participants) {
            for (const key in state.participants) {
                if (!state.user || state.participants[key].id !== state.user.uid) participants.push(Object.assign({}, state.participants[key]))
                else currentUser = key
            }
        }
        if (currentUser) {
            participants.unshift(Object.assign({}, state.participants[currentUser]))
        }
        if (state.poll.bannedWordListEnabled && state.poll.bannedWordList.length > 0) {
            for (let i = 0; i < participants.length; i++) {
                participants[i].name = censorWords(participants[i].name, state.poll.bannedWordList)
            }
        }
        return participants
    },
    activeParticipants: state => {
        let active = []
        active.push(state.user.uid)
        active.push(state.poll.owner)
        if (state.suggestions) {
            state.suggestions.forEach((suggestion) => {
                active.push(suggestion.author);
                if (state.poll.showVoters) {
                    active = active.concat(suggestion.votes)
                }
            })
            active = [...new Set(active)]
        }
        return active
    },
    participantsCount: state => state.participantsCount,
    maxVotes: state => state.maxVotes,
    openProfile: state => state.openProfile,
    tempSuggestion: state => state.tempSuggestion,
    votesGiven: (state) => {
        let votes = 0
        if (state.suggestions && state.suggestions.length > 0) {
            state.suggestions.forEach((sug) => {
                sug.votes.forEach((vote) => {
                    if (vote === state.user.uid) votes += 1
                })
            })
        }
        return votes
    },
    votesLeft: (state, getters) => {
        return state.poll.votesPerUser - getters.votesGiven
    },
    hasVotesLeft: (state, getters) => {
        if (state.embed) {
            return false
        }
        if (state.poll.votesPerUser <= -1) {
            return true
        } else {
            if (getters.votesLeft > 0) return true
            else return false
        }
    },
    hasInfiniteVotes: (state) => {
        if (state.poll.votesPerUser <= -1) return true
        else return false
    }
}

// actions
const actions = {
    setId({commit}, id) {
        commit(types.POLL_SET_ID, id)
    },
    setUser({commit}, user) {
        commit(types.POLL_SET_USER, user)
    },
    async initWatchers({commit, state, dispatch}) {
        let userIds = []
        const api = poll

        onSnapshot(api.watch(state.id), function (snapshot) {
            let poll = snapshot.data()
            commit(types.POLL_SET_POLL, poll)
            userIds.push(state.poll.owner)
            dispatch('syncParticipants')
        }.bind(this));


        onSnapshot(api.watchSuggestions(state.id), querySnapshot => {
            var suggestions = [];
            querySnapshot.forEach(function (doc) {
                let suggestion = doc.data();
                suggestion.id = doc.id;
                suggestions.push(suggestion);
                userIds.push(suggestion.author);
                userIds = userIds.concat(suggestion.votes)
            });
            commit(types.POLL_SET_SUGGESTIONS, suggestions)
            dispatch('syncParticipants')
        })
    },
    async initAdminMeta({commit, state}, adminId) {
        await poll.loadAdminMeta(state.id, adminId).then(response => {
            if (response.data()) {
                let admin = response.data()
                admin.id = response.id
                commit(types.POLL_SET_ADMIN, admin)
                commit(types.POLL_SET_USER, {uid: state.poll.owner})
            }
        })
        if (state.admin) {
            onSnapshot(poll.watchAdminMeta(state.id, state.admin.id), snapshot => {
                let admin = snapshot.data()
                admin.id = snapshot.id
                commit(types.POLL_SET_ADMIN, admin)
            })
        } else {
            const users = await poll.getParticipants(state.id, [adminId])
            if (users[0]) commit(types.POLL_SET_USER, {uid: users[0].id})
        }
    },
    async initEmbed({commit, state}) {

        commit(types.POLL_SET_EMBED, true)

        let userIds = [];

        await poll.get(state.id).then((doc) => {
            if (doc.exists) {
                commit(types.POLL_SET_POLL, doc.data())
                userIds.push(state.poll.owner)
            }
        });

        await poll.getSuggestions(state.id, 3).then((querySnapshot) => {
            var suggestions = [];
            querySnapshot.forEach(function (doc) {
                let suggestion = doc.data();
                suggestion.id = doc.id;
                suggestions.push(suggestion);
                userIds.push(suggestion.author);
                //userIds.concat(suggestion.votes)
            });
            commit(types.POLL_SET_SUGGESTIONS, suggestions)
        })

        poll.getParticipants(state.id, userIds).then((querySnapshot) => {
            var participants = [];
            querySnapshot.forEach(function (doc) {
                participants.push(doc);
            });
            commit(types.POLL_SET_PARTICIPANTS, participants)
        })
    },
    async loadAllParticipants({commit, getters}) {
        let all = []
        let snapshot = await poll.getAllParticipants(state.id)
        snapshot.forEach((doc) => {
            let participant = doc.data();
            participant.docId = doc.id;
            all.push(participant);
        })
        poll.getParticipants(state.id, getters.activeParticipants).then((querySnapshot) => {
            querySnapshot.forEach(function (participant) {
                participant.docId = participant.id;
                if (!all.find(a => a.id === participant.id)) {
                    all.push(participant);
                }
            });
            commit(types.POLL_SET_PARTICIPANTS, all)
        })
    },
    async syncParticipants({commit, state, getters}) {
        if (pariticpantsSyncing) {
            return;
        }
        pariticpantsSyncing = true
        let toLoadIds = []
        const count = await poll.countParticipants(state.id);
        commit(types.POLL_SET_PARTICIPANTS_COUNT, count)
        if (count <= 10) {
            let snapshot = await poll.getAllParticipants(state.id)
            snapshot.forEach((doc) => {
                toLoadIds.push(doc.data().id);
            })
        }
        const activeParticipants = getters.activeParticipants
        activeParticipants.forEach((id) => {
            if (!state.participants || !state.participants.find((p) => p.id === id)) {
                toLoadIds.push(id)
            }
        })
        toLoadIds = [...new Set(toLoadIds)]
        var newParticipants = []
        if (toLoadIds.length) {
            poll.getParticipants(state.id, [...toLoadIds]).then((querySnapshot) => {
                querySnapshot.forEach(function (participant) {
                    newParticipants.push(participant);
                });
                commit(types.POLL_SYNC_PARTICIPANTS, {newParticipants, getters})
                pariticpantsSyncing = false
            })
        } else {
            commit(types.POLL_SYNC_PARTICIPANTS, {newParticipants, getters})
        }

        while (toLoadIds.length) {
            const batch = toLoadIds.splice(0, 30);
            watchers.participants.push(onSnapshot(poll.watchParticipants(state.id, batch), snapshot => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === 'modified') {
                        commit(types.POLL_REPLACE_PARTICIPANT, change.doc.data())
                    }
                })
            }))
        }
    },
    async addParticipant({state, dispatch}, participant) {
        poll.addParticipant(state.id, participant).then(() => {
            dispatch('syncParticipants')
        })
    },
    async updateParticipant({state, dispatch, commit}, participant) {
        poll.updateParticipant(state.id, participant)
        commit(types.POLL_REPLACE_PARTICIPANT, participant)
        dispatch('syncParticipants')
    },
    async addSuggestion({state, dispatch}, suggestion) {
        const promise = poll.addSuggestion(state.id, suggestion)
        dispatch('syncParticipants')
        return promise
    },
    async updateSuggestion({state, dispatch}, suggestion) {
      poll.updateSuggestion(state.id, suggestion)
      dispatch('syncParticipants')
    },
    vote({state, getters, dispatch}, suggestion) {
        if (!suggestion.votes || suggestion.votes.indexOf(state.user.uid) == -1) {
            if (getters.hasVotesLeft) {
                poll.vote(state.id, suggestion.id, state.user.uid)
                dispatch('syncParticipants')
                return true
            } else {
                return false
            }
        } else {
            poll.unVote(state.id, suggestion.id, state.user.uid);
            dispatch('syncParticipants')
            return true
        }
    },
    deleteSuggestion({state, dispatch}, suggestion) {
        poll.deleteSuggestion(state.id, suggestion.id);
        dispatch('syncParticipants')
    },
    finishPoll() {
        poll.update(state.id, state.admin.id, {finished: true})
    },
    reopenPoll() {
        poll.update(state.id, state.admin.id, {finished: false})
    },
    updateAdminOptions({state, dispatch}, data) {
        poll.update(state.id, state.admin.id, data)
        dispatch('syncParticipants')
    },
    openProfile({commit}) {
        commit(types.POLL_OPEN_PROFILE)
    },
    closeProfile({commit}) {
        commit(types.POLL_CLOSE_PROFILE)
    },
    updateTempSuggestion({commit}, data) {
        commit(types.SUGGESTION_SET_TEMP, data)
    },
    setEmbedClicked({commit}, isClicked) {
        commit(types.POLL_SET_EMBED_CLICKED, isClicked)
    },
}

// mutations
const mutations = {
    [types.POLL_SET_ID](state, id) {
        state.id = id
    },
    [types.POLL_SET_USER](state, user) {
        localStorage.setItem('pollly_uid_' + state.id, user.uid)
        state.user = user
    },
    [types.POLL_SET_POLL](state, poll) {
        state.poll = poll
    },
    [types.POLL_SET_ADMIN](state, admin) {
        state.admin = admin
    },
    [types.POLL_SET_SUGGESTIONS](state, suggestions) {
        state.suggestions = suggestions

        state.maxVotes = 0;
        suggestions.forEach(suggestion => {
            if (suggestion.votes.length > state.maxVotes) {
                state.maxVotes = suggestion.votes.length
            }
        })
    },
    [types.POLL_SET_PARTICIPANTS](state, participants) {
        state.participants = participants
    },
    [types.POLL_SYNC_PARTICIPANTS](state, {newParticipants, getters}) {
        state.participants = (state.participants) ? state.participants : []

        newParticipants.forEach((np) => {
            if (!state.participants.find((p) => p.id === np.id)) {
                state.participants.push(np)
            }
        })

        let toDeleteIds = [];
        if (state.participants && state.participants.length > 10) {
            state.participants.forEach((p) => {
                if (getters.activeParticipants.indexOf(p.id) === -1) {
                    toDeleteIds.push(p.id)
                }
            })
        }
        toDeleteIds.forEach((id) => {
            let index = state.participants.findIndex(p => p.id === id)
            if (index >= 0) {
                state.participants.splice(index, 1)
            }
        })
    },
    [types.POLL_REPLACE_PARTICIPANT](state, participant) {
        const index = state.participants.findIndex((p) => p.id === participant.id)
        if (index >= 0) {
            Vue.set(state.participants, index,participant)
        }
    },
    [types.POLL_REMOVE_PARTICIPANT](state, participant) {
        const index = state.participants.findIndex((p) => p.id === participant.id)
        if (index >= 0) {
            state.participants.splice(index, 1)
        }
    },
    [types.POLL_SET_PARTICIPANTS_COUNT](state, count) {
        state.participantsCount = count
    },
    [types.POLL_OPEN_PROFILE](state) {
        state.openProfile = true;
    },
    [types.POLL_CLOSE_PROFILE](state) {
        state.openProfile = false;
    },
    [types.SUGGESTION_SET_TEMP](state, data) {
        state.tempSuggestion = data;
    },
    [types.POLL_SET_EMBED](state, data) {
        state.embed = data;
    },
    [types.POLL_SET_EMBED_CLICKED](state, data) {
        state.embedClicked = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
