import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/app.scss'
import VueInputAutowidth from 'vue-input-autowidth'
import Inview from 'vueinview'
import VueSocialSharing from 'vue-social-sharing'
import Ads from 'vue-google-adsense'
import VueKofi from 'vue-kofi';

import linkify from 'vue-linkify'
import '@/firebaseInit'

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(VueSocialSharing)
Vue.use(VueInputAutowidth)
Vue.use(Inview)
Vue.use(VueKofi);
Inview.offset(60)
Vue.directive('linkified', linkify)

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

function setViewportUnits() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('resize' , function () {
  setViewportUnits()
})

setViewportUnits()
