import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

var router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/Home.vue')
        },
        {
            path: '/privacy',
            name: 'privacy-policy',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/Privacy.vue')
        },
        {
            path: '/brainstorming',
            name: 'brainstorming',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/landingpages/Brainstorming.vue')
        },
        {
            path: '/free',
            name: 'free',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/landingpages/Free.vue')
        },
        {
            path: '/no-registration',
            name: 'no-registration',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/landingpages/NoRegistration.vue')
        },
        {
            path: '/realtime',
            name: 'realtime',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/landingpages/Realtime.vue')
        },
        {
            path: '/interactive',
            name: 'interactive',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/landingpages/Interactive.vue')
        },
        {
            path: '/free-upvote-tool',
            name: 'free-upvote-tool',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/landingpages/FreeUpvoteTool.vue')
        },
        {
            path: '/tool-to-vote-on-ideas',
            name: 'tool-to-vote-on-ideas',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/landingpages/ToolToVoteOnIdeas.vue')
        },
        {
            path: '/embed/:id',
            name: 'embed',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/Embed.vue')
        },
        {
            path: '/:id',
            name: 'poll',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/Poll.vue')
        },
        {
            path: '/:id/:adminKey',
            name: 'poll-admin',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/Poll.vue')
        },
    ]
})
export default router;

router.beforeEach((to, from, next) => {
    // Redirect if fullPath begins with a hash (ignore hashes later in path)
    if (to.fullPath.substr(0,2) === "/#") {
        const path = to.fullPath.substr(2);
        next(path);
        return;
    }
    next();
});