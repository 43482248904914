<template>
  <div id="app" class="app">
    <router-view/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'App',
  computed: {
    ...mapGetters('app', {
        currentBreakpoint: 'currentBreakpoint'
    })
  },
  methods: {
    ...mapActions('app', {
      updateBreakpoint: 'updateBreakpoint'
    }),
    getBreakpoint: function () {
      return window.getComputedStyle(document.querySelector('#app'), ':before').getPropertyValue('content').replace(/"/g, '');
    }
  },
  created: function () {
    this.updateBreakpoint(this.getBreakpoint())
    setTimeout(() => {
      this.updateBreakpoint(this.getBreakpoint())
    }, 500);
  },
  mounted() {
    window.addEventListener('resize' , function () {
      this.updateBreakpoint(this.getBreakpoint())
    }.bind(this))
  }
}
</script>