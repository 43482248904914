import {
    collection,
    doc,
    addDoc,
    setDoc,
    getDoc,
    getDocs,
    updateDoc,
    deleteDoc,
    arrayUnion,
    arrayRemove,
    getCountFromServer,
    query,
    where,
    orderBy,
    limit
} from "firebase/firestore";
import { db } from '@/firebaseInit';

export default {
    create(data) {
        return addDoc(collection(db, 'polls'), data)
    },
    addAdminMeta(pollId, meta) {
        return addDoc(collection(db, `polls/${pollId}/admin`), meta)
    },
    async loadAdminMeta(pollId, id = null) {
        let adminId = id
        if (!adminId) {
            let snapshot = await getDocs(collection(db,`polls/${pollId}/admin`))
            snapshot.forEach(document => {
                adminId = document.id;
            })
        }
        return getDoc(doc(db,`polls/${pollId}/admin/${adminId}`))
    },
    watchAdminMeta(pollId, adminId) {
        return doc(db,`polls/${pollId}/admin/${adminId}`)
    },
    update(pollId, adminId, data) {
        return updateDoc(doc(db,`polls/${pollId}/admin/${adminId}`), data)
    },
    watch(pollId) {
        return doc(db,`polls/${pollId}`)
    },
    get(pollId) {
        return getDoc(doc(db,`polls/${pollId}`))
    },
    watchParticipants(pollId, ids) {
        return query(collection(db,`polls/${pollId}/participants`), where('id', 'in', ids))
    },
    getParticipants(pollId, userIds) {
        return this.getContentById(pollId, 'participants', userIds)
    },
    getAllParticipants(pollId) {
        let q = query(collection(db,`polls/${pollId}/participants`), limit(200))
        return getDocs(q)
    },
    addParticipant(pollId, participant) {
        return addDoc(collection(db,`polls/${pollId}/participants`), participant)
    },
    updateParticipant(pollId, participant) {
        return updateDoc(doc(db,`polls/${pollId}/participants/${participant.docId}`), participant)
    },
    async countParticipants(pollId) {
        let snaptshot = await getCountFromServer(collection(db,`polls/${pollId}/participants`))
        return snaptshot.data().count
    },
    watchSuggestions(pollId) {
        return collection(db, `polls/${pollId}/suggestions`)
    },
    getSuggestions(pollId, l) {
        const q = query(collection(db,`polls/${pollId}/suggestions`), orderBy('votes', 'desc'), limit(l))
        return getDocs(q)
    },
    addSuggestion(pollId, suggestion) {
        return addDoc(collection(db, `polls/${pollId}/suggestions`), suggestion)
    },
    updateSuggestion(pollId, suggestion) {
        return updateDoc(doc(db, `polls/${pollId}/suggestions/${suggestion.id}`), {
            title: suggestion.title
        })
    },
    deleteSuggestion(pollId, suggestionId) {
        return deleteDoc(doc(db, `polls/${pollId}/suggestions/${suggestionId}`))
    },
    vote(pollId, suggestionId, userId) {
        return setDoc(doc(db, `polls/${pollId}/suggestions/${suggestionId}`), {
            votes: arrayUnion(userId)
        }, {merge: true})
    },
    unVote(pollId, suggestionId, userId) {
        return setDoc(doc(db, `polls/${pollId}/suggestions/${suggestionId}`), {
            votes: arrayRemove(userId)
        }, {merge: true})
    },
    delete(pollId) {
        return deleteDoc(doc(db, `polls/${pollId}`))
    },
    async getContentById(pollId, identifier, ids) {
        // don't run if there aren't any ids or a path for the collection
        if (!ids || !ids.length) return [];

        const batches = [];
        while (ids.length) {
            // firestore limits batches to 10
            const batch = ids.splice(0, 10);

            // add the batch request to to a queue
            const q = query(collection(db, `polls/${pollId}/${identifier}`), where('id', 'in', [...batch]))
            batches.push(
                getDocs(q).then(results => results.docs.map(result => ({
                    docId: result.id,
                    ...result.data()
                }) ))
            )
        }

        // after all of the data is fetched, return it
        return Promise.all(batches)
            .then((content) => content.flat());
    }
}
