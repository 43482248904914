import Vue from 'vue'
import Vuex from 'vuex'
import modal from './modules/modal'
import poll from './modules/poll'
import app from './modules/app'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    modal,
    poll,
    app
  },
  strict: debug
})

const calculateSize = () =>
{
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

calculateSize()

window.addEventListener('resize', () => {
  calculateSize()
});
